// Your variable overrides

.item-bg-img {
  background-position: 50% 100%;
  background-size: cover !important;
}

.table-publikationen {
  width: 100%;
}

/* font
-------------------------------------------------- */

@font-face {
    font-family: 'relevant';
    src: url('/dist/fonts/relevant/RelevantPro-Light.eot') format('eot'),
         url('/dist/fonts/relevant/RelevantPro-Light.woff') format('woff'),
         url('/dist/fonts/relevant/RelevantPro-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: thin;

}

@font-face {
    font-family: 'relevant';
    src: url('/dist/fonts/relevant/RelevantPro-Normal.eot') format('eot'),
         url('/dist/fonts/relevant/RelevantPro-Normal.woff') format('woff'),
         url('/dist/fonts/relevant/RelevantPro-Normal.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'relevant';
    src: url('/dist/fonts/relevant/RelevantPro-Medium.eot') format('eot'),
         url('/dist/fonts/relevant/RelevantPro-Medium.woff') format('woff'),
         url('/dist/fonts/relevant/RelevantPro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: bold;

}

$font-family-sans-serif: 'relevant' !default;

$font-size-base:              1.1rem !default; // Assumes the browser default, typically `16px`

$font-weight-light:           200 !default;
$font-weight-normal:          300 !default;
$font-weight-bold:            500 !default;

$headings-font-weight:        500 !default;

/* headings
-------------------------------------------------- */

h1.title ,h2.title ,h3.title, h4.title {
  @extend .text-uppercase;
  @extend .mb-3;
}

h1.subtitle ,h2.subtitle ,h3.subtitle {
  @extend .font-weight-normal;
  @extend .mb-3;
  &:after{
    content: "";
    display: block;
    border-bottom: 3px solid;
    width: 50px;
    padding-top: 1rem
  }
}

/* colors
-------------------------------------------------- */
$orange: #f58238;
$light-orange: #FED8B1;
$grey: #777572;
$light-grey: #e8e8e8;
$dark: #212529 !default;


.text-orange{
  color: $orange !important;
}

.text-light-orange{
  color: $light-orange !important;
}

.text-dark{
  color: $dark !important;
}

.bg-orange{
  background-color: $orange !important;
}

.bg-grey{
  color: $grey !important;
}

.bg-light-grey{
  background-color: $light-grey!important;
}

.bg-light-orange{
  background-color: $light-orange !important;
}

$primary:       $orange !default;

/* Links
-------------------------------------------------- */

a.nostyle, a.nostyle:hover {
  color: $grey !important;
  text-decoration: none !important;
}

/* Border
-------------------------------------------------- */

$border-width:                0 !default;
$border-radius:               0 !default;

/* Table
-------------------------------------------------- */

$table-border-width:          2px !default;
$table-border-color:          $light-grey !default;

$table-cell-padding:          0 !default;

td p {
  margin-bottom:0 !important
}

/* Spacing
-------------------------------------------------- */

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 2.5),
    5: ($spacer * 5)
  ),
  $spacers
);

.add-whitespace, .section {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 768px) {
  .add-whitespace, .section {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media (min-width: 992px) {
  .add-whitespace, .section {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (min-width: 1286px) {
  .add-whitespace, .section {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
}

@media (min-width: 1630px) {
  .add-whitespace, .section {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
}

.paragraph-0 p {
  margin-bottom:0 !important;
}

/* Grid
-------------------------------------------------- */

$grid-columns:                12 !default;
$grid-gutter-width:           50px !default;

/* Sticky footer styles
-------------------------------------------------- */
$footer-height: 300px !important;

html {
  position: relative;
  min-height: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Naviagtion
-------------------------------------------------- */

$navbar-padding-y: $spacer !default;

.sticky{
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  transition: padding 300ms ease-out;
}

.nav-item{
  font-size:1.2rem;
  font-weight: 400 !important;
}

.navbar-brand > img{
  width: 16rem;
  transition: width 300ms ease-out;
  margin: 1rem 0 1rem 0;
}

.sticky.is-sticky {
  padding-top: ($navbar-padding-y / 2)!important;
  padding-bottom: ($navbar-padding-y / 2)!important;
  border-bottom: 2px $light-grey solid !important;
  transition: padding 300ms ease-out;

    & .navbar-brand img{
      width: 10rem;
      transition: width 300ms ease-out; 
    }
}

@media (max-width: 768px) {
  li {
    border-top: 2px $light-grey solid;
  }
  .nav-link {
    padding: 1rem 0 1rem 0 !important;
  }
 }

/* Cards Overlay
-------------------------------------------------- */


.caption {
    position:absolute;
    top:0;
    right:0;
    background:rgba(245, 130, 56, 0.8);
    width:100%;
    height:100%;
    padding:2%;
    display: none;
    text-align:center;
    z-index:2;
    & a{
      color:#fff !important;
    }
}

.buttons {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
    & .btn {
      font-size: 25px !important;
    }
}
