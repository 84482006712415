/* Cookiealert
-------------------------------------------------- */

.cookiealert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
    z-index: 999;
    opacity: 0;
    border-radius: 0;
    transform: translateY(100%);
    transition: all 500ms ease-out;
    color: #ecf0f1;
    background: #212327
}

.cookiealert.show {
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 1000ms;
}

.cookiealert a {
    text-decoration: underline
}

.cookiealert .acceptcookies {
    margin-left: 10px;
    vertical-align: baseline;
}
